import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import { useGeneral } from "../../contexts/generalContext";
import SkillPreviewContainer from "../skill/SkillPreviewContainer";
import { copyText } from "../../utils/textUtils";

const SkillDetail = ({ quest, closeTab }) => {
  const { views, tabActive } = useGeneral();
  const activeItem = views.skills[tabActive.skills];
  const { skillId, dbName, skillName, maxLv, seperateLv } = activeItem;

  return skillId === -1 ? null : (
    <Form className="w-row-skill position-relative">
      <Button
        onClick={() => closeTab(quest)}
        variant="outline-danger"
        className="btn-close-tab"
        title="Close Tab"
      >
        <span role="img" aria-label="Close Tab">
          ❌
        </span>
      </Button>
      <Form.Group>
        <Row className="g-2 mb-3">
          <Col xs={{ span: 4, order: 3 }} md={{ span: 1, order: 0 }}>
            <Form.Label className="col-form-label">ID</Form.Label>
            <InputGroup>
              <FormControl
                readOnly
                type="text"
                size="sm"
                placeholder="Skill ID"
                aria-label="Skill ID"
                value={skillId}
              />
              <Button
                size="sm"
                title="Copy"
                onClick={() => copyText(skillId)}
                variant="outline-secondary"
              >
                <span role="img" aria-label="Copy">
                  📃
                </span>
              </Button>
            </InputGroup>
          </Col>
          <Col xs={6} md={2}>
            <Form.Label className="col-form-label">DB Name</Form.Label>
            <InputGroup>
              <FormControl
                readOnly
                type="text"
                size="sm"
                placeholder="DB Name"
                aria-label="DB Name"
                value={dbName}
              />
              <Button
                size="sm"
                title="Copy"
                onClick={() => copyText(dbName)}
                variant="outline-secondary"
              >
                <span role="img" aria-label="Copy">
                  📃
                </span>
              </Button>
            </InputGroup>
          </Col>
          <Col xs={6} md={2}>
            <Form.Label className="col-form-label">Skill Name</Form.Label>
            <InputGroup>
              <FormControl
                readOnly
                type="text"
                size="sm"
                placeholder="Skill Name"
                aria-label="Skill Name"
                value={skillName}
              />
              <Button
                size="sm"
                title="Copy"
                onClick={() => copyText(skillName)}
                variant="outline-secondary"
              >
                <span role="img" aria-label="Copy">
                  📃
                </span>
              </Button>
            </InputGroup>
          </Col>
          <Col xs={3} md={1}>
            <Form.Label className="col-form-label">Max Level</Form.Label>
            <InputGroup>
              <FormControl
                readOnly
                type="text"
                size="sm"
                placeholder="Max Lv"
                aria-label="Max Lv"
                value={maxLv}
              />
            </InputGroup>
          </Col>
          <Col>
            <Form.Label className="col-form-label">Other Info</Form.Label>
            <div>
              <Button size="sm" className="me-1 mb-1" variant="outline-dark">
                Separate Level{" "}
                {seperateLv ? (
                  <span role="img" aria-label="Yes">
                    ✔️️
                  </span>
                ) : (
                  <span role="img" aria-label="No">
                    ❌
                  </span>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Form.Group>

      <SkillPreviewContainer />
    </Form>
  );
};

export default SkillDetail;
