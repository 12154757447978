import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SkillPreview from "./SkillPreview";
import { useGeneral } from "../../contexts/generalContext";
import { useSkill } from "../../contexts/skillContext";

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-lua";
import "prismjs/themes/prism.css";
import { useLocalStorageState } from "../../utils/useLocalStorageState";

const SkillPreviewContainer = () => {
  const { tabActive, views } = useGeneral();
  const { skillPreviewContainer, fetchSkillAndSet } = useSkill();

  const activeItem = views.skills[tabActive.skills];
  const {
    skillId,
    dbName,
    skillName,
    description,
    spAmount,
    maxLv,
    seperateLv,
    attackRange,
    needSkillList,
    needSkillList2,
    skillScale,
    loading,
  } = activeItem;

  const [currentServerTab, setCurrentServerTab] = useLocalStorageState(
    "RODefaultServerTab_Skill",
    "iRO"
  );
  const [luaSkillDescript, setLuaSkillDescript] = useState("");
  const [luaSkillInfoList, setLuaSkillInfoList] = useState("");

  // Print skilldescript.lua
  useEffect(() => {
    const descriptionLines = [...description];
    const code = `[SKID.${dbName}] = {
${descriptionLines.map((line, index) => {
  const l = line.replaceAll("\n", "\\n");
  if (index === 0) return `\t"${l}"`;
  return `\n\t"${l}"`;
})}
},`;
    setLuaSkillDescript(code);
    // eslint-disable-next-line
  }, [description]);

  // Print skillinfolist.lua
  useEffect(() => {
    let code = `[SKID.${dbName}] = {
\t"${dbName}",
\tSkillName = "${skillName}",
\tMaxLv = ${maxLv},
\tSpAmount = { ${spAmount.join(", ")} },
\tbSeperateLv = ${seperateLv},
\tAttackRange = { ${attackRange.join(", ")} },\n`;

    // needSkillList
    if (needSkillList.length > 0) {
      code += `\t_NeedSkillList = {`;
      needSkillList.forEach((skill, index) => {
        code += `\n\t\t{ SKID.${skill.dbName}, ${skill.level} }`;
        if (index < needSkillList.length - 1) code += `,`;
        else code += `\n`;
      });
      code += `\t}\n`;
    } else if (needSkillList2.length > 0) {
      code += `\tNeedSkillList = {`;
      needSkillList2.forEach((job, index) => {
        code += `\n\t\t[JOBID.${job.job}] = {`;
        job.skills.forEach((sk, i) => {
          code += `\n\t\t\t{ SKID.${sk.dbName}, ${sk.level} }`;
          if (i < job.skills.length - 1) {
            code += `,`;
          }
        });
        code += "\n\t\t}";
        if (index < needSkillList2.length - 1) code += `,`;
      });
      code += `\n\t}\n`;
    }

    // skillScale
    if (skillScale.length > 0) {
      code += `\tSkillScale = {`;
      skillScale.forEach((sk, index) => {
        code += `\n\t\t[${index + 1}] = { x = ${sk.x}, y = ${sk.y} }`;
        if (index < skillScale.length - 1) code += ",";
      });
      code += `\n\t}\n`;
    }

    code += `},`;

    setLuaSkillInfoList(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbName, needSkillList, needSkillList2, skillScale]);

  useEffect(() => {
    const isSameId =
      skillPreviewContainer[currentServerTab]["skillId"] === skillId;
    if (
      isSameId ||
      (isSameId && skillPreviewContainer[currentServerTab]["notFound"])
    )
      return;

    fetchSkillAndSet(currentServerTab, skillId);
    // eslint-disable-next-line
  }, [fetchSkillAndSet, currentServerTab, skillId]);

  return (
    <Row>
      <Col xs={{ span: 12, order: 1 }} md={4} className="mb-3 mb-sm-0">
        <div className="col-item-preview">
          <h5>Data from other servers</h5>
          <Tabs
            defaultActiveKey={currentServerTab}
            id=""
            onSelect={setCurrentServerTab}
          >
            {Object.keys(skillPreviewContainer).map((server) => {
              if (server === "vRO") return null;
              return (
                <Tab key={server} eventKey={server} title={server}>
                  <SkillPreview data={{ ...skillPreviewContainer[server] }} />
                </Tab>
              );
            })}
          </Tabs>
          {skillId > 0 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="external-link"
              href={`https://divine-pride.net/database/skill/${skillId}`}
            >
              Open
            </a>
          ) : null}
        </div>
      </Col>

      <Col
        xs={{ span: 12, order: 0 }}
        md={{ span: 4, order: 2 }}
        className="mb-3 mb-sm-0 col-preview-vRO"
      >
        <div className="col-item-preview">
          <h5>Data from vRO</h5>
          <div className="mt-0 mt-md-5"></div>
          <SkillPreview data={{ skillId, description, loading }} />
        </div>
      </Col>

      <Col xs={{ order: 2 }} md={4}>
        <div className="mb-3">
          <Tabs
            className="nav-tabs-skill-desc"
            defaultActiveKey="skilldescript.lua"
            transition={false}
          >
            <Tab eventKey="skilldescript.lua" title="skilldescript.lua">
              <Editor
                className="lua-parser"
                readOnly={true}
                value={luaSkillDescript}
                highlight={() =>
                  highlight(luaSkillDescript, languages.lua, "lua")
                }
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 11,
                }}
              />
            </Tab>
            <Tab eventKey="skillinfolist.lua" title="skillinfolist.lua">
              <Editor
                className="lua-parser"
                readOnly={true}
                value={luaSkillInfoList}
                highlight={() =>
                  highlight(luaSkillInfoList, languages.lua, "lua")
                }
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 11,
                }}
              />
            </Tab>
          </Tabs>
        </div>
      </Col>
    </Row>
  );
};

export default SkillPreviewContainer;
