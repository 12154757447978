import React, { useLayoutEffect, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SkillTabs from "./SkillTabs";
import Swal from "sweetalert2";
import List from "../common/List";
import ItemNavigation from "../common/ItemNavigation";
import { useUser } from "../../contexts/userContext";
import { useGeneral } from "../../contexts/generalContext";
import { useSkill, emptySkill } from "../../contexts/skillContext";

const Skill = () => {
  const { views, generalDispatch, tabActive } = useGeneral();
  const { userToken, setUserToken } = useUser();
  const activeItem = views.skills[tabActive.skills];

  const {
    skills,
    skillResults,
    skillLoading,
    hideSkillList,
    skillKeyword,
    selectSkill,
    skillDispatch,
    skillKeywordDebounce,
    searchSkills,
  } = useSkill();

  const columns = [
    {
      label: "Skill ID",
      dataKey: "skillId",
      width: 80,
    },
    {
      label: "Name",
      dataKey: "dbName",
      width: 220,
    },
  ];

  const buttons = [];

  useLayoutEffect(() => {
    generalDispatch({ type: "SET_VIEW_NAME", payload: "skills" });
  }, [generalDispatch]);

  // Search
  useEffect(() => {
    if (skills.length === 0) return;
    const results = searchSkills(skillKeywordDebounce);
    skillDispatch({ type: "SET_SKILL_RESULTS", payload: results });
    // eslint-disable-next-line
  }, [skillKeywordDebounce]);

  // Fetch items
  useEffect(() => {
    if (skills.length === 0 && !skillLoading) {
      skillDispatch({ type: "SKILL_LIST_LOADING", isLoading: true });
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "init",
          viewCat: "skills",
          value: [emptySkill],
        },
      });

      // List of items
      axios({
        url: `${process.env.REACT_APP_API}/skills`,
        method: "GET",
        headers: { "x-auth-token": userToken },
      })
        .then((res) => {
          skillDispatch({ type: "FETCH_SKILLS_SUCCESS", payload: res.data });
        })
        .catch((error) => {
          if (
            typeof error.response !== "undefined" &&
            error.response.status === 401
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.statusText,
              text: error.response.data,
              confirmButtonText: `Login`,
            }).then(() => {
              setUserToken("");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: error.name,
              text: error.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addSkillToTabs({ skillId, dbName }) {
    const skill = _.find(skills, (skill) => skill.skillId === skillId);

    // show tab if quest existed on tabs
    const skillTabIndex = _.findIndex(
      views.skills,
      (skill) => skill.skillId === skillId
    );

    if (skillTabIndex === -1) {
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "add",
          viewCat: "skills",
          value: skill,
        },
      });
    }

    selectSkill(skill);
  }

  return (
    <>
      <Row>
        <Col
          md={3}
          lg={2}
          className={`col-table-list-item mb-3 mb-md-0 ${
            hideSkillList ? "d-none" : ""
          }`}
        >
          <List
            loading={skillLoading}
            results={skillResults}
            keyword={skillKeyword}
            setKeyword={(e) =>
              skillDispatch({
                type: "SET_SKILL_KEYWORD",
                payload: e.target.value,
              })
            }
            selectItem={selectSkill}
            columns={columns}
            buttons={buttons}
            addToTabs={addSkillToTabs}
            tabUniqueField="dbName"
          />
        </Col>
        <Col md={hideSkillList ? 12 : 9} lg={hideSkillList ? 12 : 10}>
          <SkillTabs />
        </Col>
      </Row>
      {activeItem ? (
        <ItemNavigation
          id={activeItem.skillId}
          name={activeItem.skillName}
          isHide={hideSkillList}
          setHide={() =>
            skillDispatch({
              type: "SET_HIDE_SKILL_LIST",
              payload: !hideSkillList,
            })
          }
          list={skillResults}
          select={selectSkill}
          field="skillId"
        />
      ) : null}
    </>
  );
};

export default Skill;
