import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../contexts/userContext";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";

const NavbarApp = () => {
  const { userToken } = useUser();
  const location = useLocation();
  let currentPathname = location.pathname;

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid={userToken ? true : false}>
        <Navbar.Brand as={Link} to={userToken ? "/admin" : "/"}>
          RO Tools
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {userToken ? (
              <>
                <Nav.Link as={Link} to="/admin/items">
                  Items
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/monsters">
                  Monsters
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/import">
                  Import
                </Nav.Link>

                <NavDropdown title="Script tools" id="script-nav-dropdown">
                  <NavDropdown.Item
                    as={Link}
                    to="/admin/scripts"
                    active={currentPathname === "/admin/scripts"}
                  >
                    Scripts
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    as={Link}
                    to="/admin/align"
                    active={currentPathname === "/admin/align"}
                  >
                    Align
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/admin/split-join"
                    active={currentPathname === "/admin/split-join"}
                  >
                    Split & Join
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/admin/count"
                    active={currentPathname === "/admin/count"}
                  >
                    Count
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link as={Link} to="/admin/progress">
                  Progress
                </Nav.Link>
              </>
            ) : null}
          </Nav>

          <Nav>
            {userToken ? (
              <Nav.Link as={Link} to="/logout">
                Logout
              </Nav.Link>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarApp;
